
export interface Section {
    name: string;
    showName?: boolean;
    groups: Group[];
    licenses: string[];
}

export interface Group {
    name: string;
    icon: string;

    group?: string | RegExp;

    items?: Group[];
    permission?: string;
    url?: any;
}


//
// Excursiones
//

const excursionsGroup: Group[] = [
    {
        name: "MANTENIMIENTOS",
        icon: "mdi-database-outline",
        group: /^\/excursions\/maintenances/,
        items: [
            {
                name: "Zonas",
                icon: "mdi-map-clock",
                url: { name: "GetAllZones" },
                permission: "excursions.maintenances.zones",
            },
            {
                name: "Puntos de recogida",
                icon: "mdi-map-marker",
                url: { name: "GetAllMeetingPoints" },
                permission: "excursions.maintenances.meeting-points",
            },
            {
                name: "Hoteles",
                icon: "mdi-bed-outline",
                url: { name: "GetAllHotels" },
                permission: "excursions.maintenances.hotels",
            },
            {
                name: "Rutas",
                icon: "mdi-routes-clock",
                url: { name: "GetAllRoutes" },
                permission: "excursions.maintenances.routes",
            },
            {
                name: "Agencias",
                icon: "mdi-store",
                url: { name: "GetAllAgencies" },
                permission: "excursions.maintenances.agencies",
            },
            {
                name: "Operadores",
                icon: "mdi-handshake-outline",
                url: { name: "GetAllOperators" },
                permission: "excursions.maintenances.operators",
            },
            {
                name: "Transportistas",
                icon: "mdi-bus",
                url: { name: "GetAllCarriers" },
                permission: "excursions.maintenances.carriers",
            },
            {
                name: "Proveedores",
                icon: "mdi-account-tie-woman",
                url: { name: "GetAllProviders" },
                permission: "excursions.maintenances.providers",
            },
            {
                name: "Guías",
                icon: "mdi-account-voice",
                url: { name: "GetAllGuides" },
                permission: "excursions.maintenances.guides",
            },
            {
                name: "Actividades",
                icon: "mdi-ticket",
                url: { name: "GetAllActivities" },
                permission: "excursions.maintenances.activities",
            },
            {
                name: "Productos",
                icon: "mdi-sitemap-outline",
                url: { name: "GetAllProducts" },
                permission: "excursions.maintenances.products",
            },
            {
                name: "Servicios",
                icon: "mdi-google-circles-communities",
                url: { name: "GetAllServices" },
                permission: "excursions.maintenances.services",
            },
            {
                name: "Tarifas",
                icon: "mdi-tag-multiple-outline",
                url: { name: "GetAllRates" },
                permission: "excursions.maintenances.rates",
            },
            {
                name: "Programas de actividades",
                icon: "mdi-calendar-month-outline",
                url: { name: "GetAllActivitySchedules" },
                permission: "excursions.maintenances.activity-schedules",
            },
            {
                name: "Categorias de actividades",
                icon: "mdi-shape-outline",
                url: { name: "GetAllActivityCategories" },
                permission: "excursions.maintenances.activity-categories",
            },
            {
                name: "Tarifas de guía",
                icon: "mdi-shape-outline",
                url: { name: "GetAllGuideRates" },
                permission: "excursions.maintenances.guide-rates",
            },
            {
                name: "Tipos de impuestos",
                icon: "mdi-percent-box-outline",
                url: { name: "GetAllTaxes" },
                permission: "excursions.maintenances.taxes",
            },
            {
                name: "Series",
                icon: "mdi-counter",
                url: { name: "GetAllSeries" },
                permission: "excursions.maintenances.series",
            },
            {
                name: "Reglas de liquidación",
                icon: "mdi-debug-step-over",
                url: { name: "GetAllSettlementRules" },
                permission: "excursions.maintenances.settlement-rules",
            },

        ],
    },
    {
        name: "OPERATIVA",
        icon: "mdi-home-clock-outline",
        group: /^\/excursions\/operations/,
        items: [
            {
                name: "Programación y precios",
                icon: "mdi-clock-outline",
                url: { name: "ProductScheduling" },
                permission: "excursions.operations.pricing",
            },
            {
                name: "Cierre de ventas",
                icon: "mdi-pause-octagon-outline",
                url: { name: "ProductRestrictions" },
                permission: "excursions.operations.stop-sales",
            },
            {
                name: "Planificación diaria",
                icon: "mdi-clock-outline",
                url: { name: "DayScheduling" },
                permission: "excursions.operations.scheduling",
            },
            {
                name: "Incidencias de operativa",
                icon: "mdi-sync-alert",
                url: { name: "GetAllIncidents" },
                permission: "excursions.operations.incidents",
            },
            {
                name: "Autobuses en ruta",
                icon: "mdi-routes-clock",
                url: { name: "GetDayRoutes" },
                permission: "excursions.operations.routes",
            },
            {
                name: "Planificación de guías",
                icon: "mdi-bell-sleep-outline",
                url: { name: "GetGuidePreferences" },
                permission: "excursions.operations.guide-preferences",
            },
            {
                name: "Órdenes de trabajo",
                icon: "mdi-file-tree",
                url: { name: "GetAllWorkOrders" },
                permission: "excursions.operations.work-orders",
            },
        ],
    },
    {
        name: "RESERVAS",
        icon: "mdi-text-box-outline",
        group: /^\/excursions\/bookings/,
        items: [
            {
                name: "Inbox",
                icon: "mdi-tray-full",
                url: { name: "BookingInbox" },
                permission: "excursions.bookings.inbox",
            },
            {
                name: "Centro de reservas",
                icon: "mdi-warehouse",
                url: { name: "GetAllBookings" },
                permission: "excursions.bookings.booking-center",
            },
            {
                name: "Presupuestos",
                icon: "mdi-invoice-list-outline",
                url: { name: "GetAllBudgets" },
                permission: "excursions.bookings.budgets",
            },
            {
                name: "Grupos",
                icon: "mdi-account-group-outline",
                url: { name: "GetAllGroupBookings" },
                permission: "excursions.bookings.groups",
            },
            {
                name: "Nueva reserva",
                icon: "mdi-text-box-plus-outline",
                url: { name: "NewBooking" },
                permission: "excursions.bookings.booking-center",
            },
            {
                name: "Puntos de recogida",
                icon: "mdi-text-box-plus-outline",
                url: { name: "MeetingPointsInformation" },
                permission: "excursions.bookings.booking-center",
            },
        ],
    },
    {
        name: "LIQUIDACIONES",
        icon: "mdi-currency-eur",
        group: /^\/excursions\/settlements/,
        items: [
            {
                name: "Liquidaciones de ventas",
                icon: "mdi-receipt-text-clock-outline",
                url: { name: "AgencySettlement" },
                permission: "excursions.settlements.sales",
            },
            {
                name: "Liquidación operadores",
                icon: "mdi-receipt-text-clock-outline",
                url: { name: "OperatorSettlement" },
                permission: "excursions.settlements.purchases",
            },
            {
                name: "Liquidación transportistas",
                icon: "mdi-receipt-text-clock-outline",
                url: { name: "CarrierSettlement" },
                permission: "excursions.settlements.purchases",
            },
            {
                name: "Liquidación guías",
                icon: "mdi-receipt-text-clock-outline",
                url: { name: "GuideSettlement" },
                permission: "excursions.settlements.purchases",
            },
            {
                name: "Liquidación proveedores",
                icon: "mdi-receipt-text-clock-outline",
                url: { name: "ProviderSettlement" },
                permission: "excursions.settlements.purchases",
            },
        ],
    },
    {
        name: "FACTURACIÓN",
        icon: "mdi-currency-eur",
        group: /^\/excursions\/invoicing/,
        items: [
            {
                name: "Facturas de venta",
                icon: "mdi-receipt-text-outline",
                url: { name: "GetAllSalesInvoices" },
                permission: "excursions.invoicing.sales",
            },
            {
                name: "Facturas de compra",
                icon: "mdi-receipt-text-outline",
                url: { name: "GetAllPurchaseInvoices" },
                permission: "excursions.invoicing.purchases",
            },
        ],

    },
];

//
// Transfers
//

const transfersGroup: Group[] = [
    {
        name: "MANTENIMIENTOS",
        icon: "mdi-database-outline",
        group: /^\/transfers\/maintenances/,
        items: [
            {
                name: "Compañias aéreas",
                icon: "mdi-airplane",
                url: { name: "GetAllAirlines" },
                permission: "transfers.maintenances.airlines",
            },
            {
                name: "Aeropuertos",
                icon: "mdi-airport",
                url: { name: "GetAllAirports" },
                permission: "transfers.maintenances.airports",
            },
            {
                name: "Zonas de traslado",
                icon: "mdi-map-clock",
                url: { name: "GetAllTransferZones" },
                permission: "transfers.maintenances.zones",
            },
            {
                name: "Puntos de recogida",
                icon: "mdi-map-marker",
                url: { name: "GetAllMeetingPoints" },
                permission: "transfers.maintenances.meeting-points",
            },
            {
                name: "Hoteles",
                icon: "mdi-bed-outline",
                url: { name: "GetAllTransferHotels" },
                permission: "transfers.maintenances.hotels",
            },
            {
                name: "Rutas",
                icon: "mdi-routes-clock",
                url: { name: "GetAllRoutes" },
                permission: "transfers.maintenances.routes",
            },
            {
                name: "Agencias",
                icon: "mdi-store",
                url: { name: "GetAllTransferAgencies" },
                permission: "transfers.maintenances.agencies",
            },
            {
                name: "Operadores",
                icon: "mdi-handshake-outline",
                url: { name: "GetAllOperators" },
                permission: "maintenances.operators",
            },
            {
                name: "Transportistas",
                icon: "mdi-bus",
                url: { name: "GetAllCarriers" },
                permission: "maintenances.carriers",
            },
            {
                name: "Guías",
                icon: "mdi-account-voice",
                url: { name: "GetAllGuides" },
                permission: "maintenances.guides",
            },
            // {
            //     name: "Actividades",
            //     icon: "mdi-ticket",
            //     url: { name: "GetAllActivities" },
            //     permission: "maintenances.activities",
            // },
            {
                name: "Productos",
                icon: "mdi-sitemap-outline",
                url: { name: "GetAllTransferProducts" },
                permission: "transfers.maintenances.products",
            },
            {
                name: "Servicios",
                icon: "mdi-google-circles-communities",
                url: { name: "GetAllServices" },
                permission: "maintenances.services",
            },
            {
                name: "Tarifas",
                icon: "mdi-tag-multiple-outline",
                url: { name: "GetAllRates" },
                permission: "maintenances.rates",
            },
            // {
            //     name: "Categorias de actividades",
            //     icon: "mdi-shape-outline",
            //     url: { name: "GetAllActivityCategories" },
            //     permission: "maintenances.activity-categories",
            // },
            // {
            //     name: "Tarifas de guía",
            //     icon: "mdi-shape-outline",
            //     url: { name: "GetAllGuideRates" },
            //     permission: "maintenances.guide-rates",
            // },
        ],
    },
    {
        name: "OPERATIVA",
        icon: "mdi-home-clock-outline",
        group: /^\/transfers\/operations/,
        items: [
            {
                name: "Cupos",
                icon: "mdi-calendar-check-outline",
                //url: { name: "GetAllQuota" },
                permission: "operations.flights",
            },
            {
                name: "Vuelos",
                icon: "mdi-airplane",
                url: { name: "GetTransferFlights" },
                permission: "operations.flights",
            },
            // {
            //     name: "Programación y precios",
            //     icon: "mdi-clock-outline",
            //     url: { name: "ProductScheduling" },
            //     permission: "operations.pricing",
            // },
            // {
            //     name: "Cierre de ventas",
            //     icon: "mdi-pause-octagon-outline",
            //     url: { name: "ProductRestrictions" },
            //     permission: "operations.stop-sales",
            // },
            {
                name: "Planificación de llegadas",
                icon: "mdi-clock-outline",
                url: { name: "TransferDaySchedule", params: { type: "INBOUND" } },
                permission: "operations.scheduling",
            },
            {
                name: "Planificación de salidas",
                icon: "mdi-clock-outline",
                url: { name: "TransferDaySchedule", params: { type: "OUTBOUND" } },
                permission: "operations.scheduling",
            },
            {
                name: "Incidencias de operativa",
                icon: "mdi-sync-alert",
                url: { name: "GetAllTransferIncidents" },
                permission: "operations.incidents",
            },
            // {
            //     name: "Autobuses en ruta",
            //     icon: "mdi-routes-clock",
            //     url: { name: "GetDayRoutes" },
            //     permission: "operations.routes",
            // },
        ],
    },
    {
        name: "RESERVAS",
        icon: "mdi-text-box-outline",
        group: /^\/transfers\/bookings/,
        items: [
            // {
            //     name: "Inbox",
            //     icon: "mdi-tray-full",
            //     url: { name: "BookingInbox" },
            //     permission: "bookings.inbox",
            // },
            {
                name: "Centro de reservas",
                icon: "mdi-warehouse",
                url: { name: "GetAllTransferBookings" },
                permission: "transfers.bookings.booking-center",
            },
            {
                name: "Centro de clientes",
                icon: "mdi-account-group-outline",
                url: { name: "GetAllTransferCustomers" },
                permission: "transfers.bookings.customer-center",
            },
            // {
            //     name: "Grupos",
            //     icon: "mdi-account-group-outline",
            //     url: { name: "GetAllGroupBookings" },
            //     permission: "bookings.groups",
            // },
            // {
            //     name: "Nueva reserva",
            //     icon: "mdi-text-box-plus-outline",
            //     url: { name: "NewBooking" },
            //     permission: "bookings.booking-center",
            // },
            // {
            //     name: "Puntos de recogida",
            //     icon: "mdi-text-box-plus-outline",
            //     url: { name: "MeetingPointsInformation" },
            //     permission: "bookings.booking-center",
            // },
            // {
            //     name: "Pagos electrónicos",
            //     icon: "mdi-credit-card-outline",
            //     url: { name: "GetAllPayments" },
            //     permission: "bookings.e-payments",
            // },
        ],
    },
    {
        name: "LIQUIDACIONES",
        icon: "mdi-currency-eur",
        group: /^\/transfers\/settlements/,
        items: [
            {
                name: "Liquidación touroperadores",
                icon: "mdi-receipt-text-clock-outline",
                url: { name: "AgencySettlement", params: { type: "ttoo" } },
                permission: "billing.sales-settlements",
            },
            // {
            //     name: "Liquidación puntos de venta",
            //     icon: "mdi-receipt-text-clock-outline",
            //     url: { name: "AgencySettlement", params: { type: "pos" } },
            //     permission: "billing.sales-settlements",
            // },
            // {
            //     name: "Liquidación grupos",
            //     icon: "mdi-receipt-text-clock-outline",
            //     url: { name: "AgencySettlement", params: { type: "direct" } },
            //     permission: "billing.sales-settlements",
            // },
            // {
            //     name: "Facturas de venta",
            //     icon: "mdi-receipt-text-outline",
            //     url: { name: "GetAllSalesInvoices" },
            //     permission: "billing.sales-invoices",
            // },
        ],
    },
    {
        name: "FACTURACIÓN",
        icon: "mdi-currency-eur",
        group: /^\/excursions\/invoicing/,
        items: [
            {
                name: "Facturas de venta",
                icon: "mdi-receipt-text-outline",
                url: { name: "GetAllSalesInvoices" },
                permission: "billing.sales-invoices",
            },
            {
                name: "Facturas de compra",
                icon: "mdi-receipt-text-outline",
                url: { name: "GetAllPurchaseInvoices" },
                permission: "billing.purchase-invoices",
            },
        ],
    },
];


//
// Global
//

const globalGroup: Group[] = [
    {
        name: "AYUDA",
        icon: "mdi-tooltip-question-outline",
        url: { name: "HelpCenter" },
        permission: "global.help-center",
    },
    {
        name: "CONTENIDO",
        icon: "mdi-multimedia",
        // group: "content",
        group: /^\/global\/content/,
        items: [
            {
                name: "Galería de imágenes",
                icon: "mdi-image-multiple-outline",
                url: { name: "GetAllImages" },
                permission: "global.content",
            },
            {
                name: "Elementos de contenido",
                icon: "mdi-folder-text-outline",
                url: { name: "GetAllContentElements" },
                permission: "global.content",
            },
            {
                name: "Plantillas de mensajes",
                icon: "mdi-message-cog-outline",
                url: { name: "GetAllMessageTemplates" },
                permission: "global.content",
            },
        ],
    },
    {
        name: "CENTRO DE INFORMES",
        icon: "mdi-file-chart-outline",
        url: { name: "ReportCenter" },
        permission: "global.report-center",
    },
    {
        name: "DOCUMENTACIÓN",
        icon: "mdi-printer-outline",
        url: { name: "documentation-report", params: { websiteId: "" } },
        permission: "global.documentation",
    },
    {
        name: "RECURSOS HUMANOS",
        icon: "mdi-human-queue",
        group: /^\/global\/hhrr/,
        items: [
            {
                name: "Empleados",
                icon: "mdi-account-hard-hat-outline",
                url: { name: "GetAllEmployees" },
                permission: "global.hhrr.employees",
            },
            {
                name: "Calendario laboral",
                icon: "mdi-calendar-multiselect-outline",
                url: { name: "GetAllWorkCalendars" },
                permission: "global.hhrr.work-calendars",
            },
            {
                name: "Incidencias",
                icon: "mdi-clock-digital",
                url: { name: "GetAllTimeRecordIncidents" },
                permission: "global.hhrr.time-records",
            },
            {
                name: "Registros horarios",
                icon: "mdi-clock-digital",
                url: { name: "GetAllTimeRecords" },
                permission: "global.hhrr.time-records",
            },
            {
                name: "Vista mensual",
                icon: "mdi-calendar-month-outline",
                url: { name: "GetTimeRecordMonthlyView" },
                permission: "global.hhrr.monthly-view",
            },
            {
                name: "Vista anual",
                icon: "mdi-calendar-month-outline",
                url: { name: "GetTimeRecordYearlyView" },
                permission: "global.hhrr.yearly-view",
            },
        ]
    },
    {
        name: "ADMINISTRACIÓN",
        icon: "mdi-security",
        group: /^\/global\/administration/,
        items: [
            // {
            //     name: "Temporadas",
            //     icon: "mdi-calendar-month-outline",
            //     url: { name: "GetAllSeasons" },
            //     permission: "administration.seasons",
            // },
            {
                name: "Empresas",
                icon: "mdi-domain",
                url: { name: "GetAllOrganizations" },
                permission: "global.administration.organizations",
            },
            {
                name: "Perfiles",
                icon: "mdi-account",
                url: { name: "GetAllProfiles" },
                permission: "global.administration.profiles",
            },
            {
                name: "Usuarios (CRS)",
                icon: "mdi-account-multiple",
                url: { name: "GetAllUsers", params: { type: "crs" } },
                permission: "global.administration.users",
            },
            {
                name: "Usuarios (Agencias)",
                icon: "mdi-account-multiple",
                url: { name: "GetAllUsers", params: { type: "agency" } },
                permission: "global.administration.users",
            },
            {
                name: "Usuarios (Guías)",
                icon: "mdi-account-multiple",
                url: { name: "GetAllUsers", params: { type: "guide" } },
                permission: "global.administration.users",
            },
            {
                name: "Notificaciones automáticas",
                icon: "mdi-send-variant-clock-outline",
                url: { name: "GetAllAutomaticNotificationRules" },
                permission: "global.administration.automatic-notification-rules",
            },
            {
                name: "Pagos electrónicos",
                icon: "mdi-credit-card-outline",
                url: { name: "GetAllPayments" },
                permission: "global.administration.e-payments",
            },

        ],
    },
    {
        name: "CONFIGURACIÓN",
        icon: "mdi-cog",
        group: /^\/global\/configuration/,
        items: [
            {
                name: "Parámetros",
                icon: "mdi-cog",
                url: { name: "EditConfiguration" },
                permission: "global.configuration.parameters",
            },
            {
                name: "Notificaciones",
                icon: "mdi-tray-full",
                url: { name: "GetAllNotifications" },
                permission: "global.configuration.notifications",
            },
            {
                name: "Cuentas de correo",
                icon: "mdi-email-outline",
                url: { name: "GetAllMailAccounts" },
                permission: "global.configuration.mail-accounts",
            },
            {
                name: "Cuentas de WhatsApp",
                icon: "mdi-whatsapp",
                url: { name: "GetAllWhatsAppAccounts" },
                permission: "global.configuration.whatsapp-accounts",
            },
            {
                name: "Integraciones",
                icon: "mdi-memory",
                url: { name: "GetAllIntegrations" },
                permission: "global.configuration.integrations",
            },
            {
                name: "Sitios web",
                icon: "mdi-web",
                url: { name: "GetAllWebsites" },
                permission: "global.configuration.websites",
            },
            {
                name: "Métodos de pago",
                icon: "mdi-account-credit-card-outline",
                url: { name: "GetAllPaymentMethods" },
                permission: "global.configuration.payment-methods",
            },
            {
                name: "Claves de acceso al API",
                icon: "mdi-api",
                url: { name: "GetAllKeys" },
                permission: "global.configuration.keys",
            },
            {
                name: "Mantenimiento",
                icon: "mdi-wrench-clock",
                url: { name: "Maintenance" },
                permission: "global.configuration.maintenance",
            },
        ],
    },
];

export const sections: Section[] = [
    {
        name: "TRASLADOS",
        showName: true,
        groups: transfersGroup,
        licenses: ["4"]
    },
    {
        name: "EXCURSIONES",
        showName: true,
        groups: excursionsGroup,
        licenses: ["0", "1", "2", "3", "4"]
    },
    {
        name: "SITIOS WEB",
        showName: true,
        groups: [],
        licenses: ["0", "1", "2", "3", "4"]
    },
    {
        name: "GLOBAL",
        showName: true,
        groups: globalGroup,
        licenses: ["0", "1", "2", "3", "4"]
    }
];
